/* video css */
.vjs-big-play-button {
  width: 52.5px !important;
  height: 52.5px !important;
  color: #151c2e !important;
  background-color: white !important;
  border-radius: 50px !important;
}

.vjs-big-play-button > .vjs-icon-placeholder:before {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-js {
  width: 100% !important;
  height: 100% !important;
}

.video-js .vjs-volume-menu-button {
  display: none !important;
}
.video-js.vjs-fullscreen .vjs-volume-menu-button {
  display: block !important;
}
.video-js .vjs-play-progress {
  background-color: #00c1b2 !important;
  height: 8px !important;
}
.video-js .vjs-slider {
  background-color: #fff !important;
  height: 8px !important;
}
.video-js .vjs-load-progress div {
  background-color: transparent !important;
}
.video-js .vjs-play-progress:before {
  content: none !important;
}
.vjs-volume-panel {
  display: none !important;
}
/* .vjs-play-control {
  display: none !important;
} */
.vjs-control-bar {
  height: 40px !important;
}
.video-js .vjs-current-time {
  display: flex !important;
  align-items: center;
  padding: 0px 0px 0 10px !important;
  font-size: 14px !important;
  line-height: 2 !important;
}
.video-js .vjs-time-divider {
  padding: 0px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 14px !important;
  line-height: 2 !important;
  min-width: 1em !important;
}
.video-js .vjs-duration {
  display: flex !important;
  align-items: center;
  padding: 0 !important;
  font-size: 14px !important;
  line-height: 2 !important;
}
.vjs-button > .vjs-icon-placeholder:before {
  font-size: 22px !important;
  line-height: 40px !important;
}
.vjs-remaining-time {
  display: none !important;
}
.vjs-picture-in-picture-control {
  display: none !important;
}
.vjs-big-play-centered .vjs-big-play-button {
  transform: translateX(50%) !important;
}
