#task-remark-add-attachment ul.MuiList-root.MuiList-padding.MuiMenu-list {
    padding-top: 4px;
    padding-bottom: 4px;
}

#task-remark-add-attachment ul.MuiList-root.MuiList-padding.MuiMenu-list li.MuiButtonBase-root.MuiMenuItem-root {
    padding: 8px 12px;
}

#task-remark-add-attachment > .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper {
    box-shadow: 0 0 0 0;
    border: 1px solid rgb(229, 231, 235);
    transform: translateX(-7px) translateY(-14px) !important;
}

@media screen and (max-width: 1023px) {
    #task-remark-add-attachment > .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper {
        transform: translateY(-14px) !important;
    }
}