.button-filter {
    padding: 6px 12px;
    margin-right: 16px;
    border-radius: 6px;
    border: 1px solid var(--Grey-300---Divider, #D9DDDD);
    font-size: 14px;
    line-height: 1.5em;
    cursor: pointer;
  }
  
  .button-filter:hover {
    background-color: rgba(0, 0, 0, 0);
  }